import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import { CollapsedForm, CollapsedFormType } from '../../../shared/collapsed-form/CollapsedForm';
import { Option } from '../../../Option';
import Button, { ButtonSize, ButtonType } from '../../../shared/form-control/Button';
import CompoundInput from '../../../shared/form-control/CompoundInput';
import PickListModal from '../../../shared/pick-list/PickListModal';
import { ClientForm } from '../../../../models/ClientForm';
import PickListService from '../../../../services/PickListService';
import { useTranslation } from 'react-i18next';
import ClientFormService from '../../../../services/ClientFormService';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { ChildFormRenderType } from './ChildFormRenderType';
import { ToastType, useToasts } from '../../../../contexts/ToastContext';
import { FormType } from '../../../../models/FormTypes';
import { AssetTemplateSourceKey, CustomSourceKeyAssociciatedClients } from '../../../../models/Picklist';
import ClientService from '../../../../services/ClientService';
import { FormConfig } from '../../../../models/Form';
import TemplateFormService from '../../../../services/TemplateFormService';
import FormUtils from '../../../../utils/FormUtils';
import ActionBaseProps from '../ActionBaseProps';
import { FormRendererType, useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { useFormAction } from '../../../../contexts/FormActionContext';
import ClientTemplateFormService from '../../../../services/ClientTemplateFormService';
import ActionTitleDescription from '../ActionTitleDescription';
import { Translations } from '../../../../models/Translation';

export type ChildForm = {
  id: string;
};

export type ChildFormListActionData = {
  templateFormId: string;
  // templateFormVersion: number;
  sourceType: string;
  renderType: ChildFormRenderType;
};

export type ChildFormListActionProps = ActionBaseProps<ChildForm[], ChildFormListActionData>;

const ChildFormListAction: FC<ChildFormListActionProps> = (props) => {
  const { response, data, required } = props;
  const { question, templateFormId, sourceType, renderType } = data;
  const { clientFormId, containingModuleId, containingModuleSectionId, isDisplayedInline, createFormBulk, type } = useFormRendererInfo();
  const { onAnswer, onValid, currentSection, currentAction, readOnly } = useFormAction(props);

  const { t } = useTranslation('activity-type');
  const [forms, setForms] = useState(response || []);
  const [picklistOpen, setPicklistOpen] = useState(false);
  const [picklistId, setPicklistId] = useState('');
  const [picklistTitle, setPicklistTitle] = useState('');
  const [picklistLoading, setPicklistLoading] = useState(false);
  const [picklistOptions, setPicklistOptions] = useState<Option<string, string>[]>([]);
  const [setupError, setSetupError] = useState(false);
  const [isDynamic, setIsDynamic] = useState(false);
  const [isAssetTemplate, setIsAssetTemplate] = useState(false);
  const [customInput, setCustomInput] = useState('');
  const [template, setTemplate] = useState<FormConfig | null>(null);
  const toasts = useToasts();
  const client = useRecoilValue(currentClientAtom);
  const clientId = client?.id || '';

  const templateService = useMemo(() => {
    if (client) {
      return new ClientTemplateFormService(clientId);
    }
    return TemplateFormService;
  }, [client, clientId]);

  useEffect(() => {
    setForms(response || []);
  }, [response]);

  const getAffiliatedEntries = useCallback(async () => {
    if (!client) {
      setPicklistTitle(t('picklist.affiliated-company', { client: 'Client C' }));
      setPicklistOptions([
        { id: 'A', text: 'Client A', value: 'Client A' },
        { id: 'B', text: 'Client B', value: 'Client B' },
        { id: 'C', text: 'Client C', value: 'Client C' },
      ]);
      setPicklistLoading(false);
      return Promise.resolve();
    }

    const res = await ClientService.getAffiliates(client.id || '');
    setPicklistId(CustomSourceKeyAssociciatedClients);
    setPicklistTitle(t('picklist.affiliated-company', { client: client.name }));
    setPicklistOptions(
      res.data.map((client) => ({
        id: client.id,
        text: client.name,
        value: '',
      })),
    );
    setPicklistLoading(false);
  }, [client, t]);

  useEffect(() => {
    // action not set up
    if (!templateFormId) {
      return;
    }
    templateService.getFormTemplate(templateFormId).then((res) => {
      setTemplate(res.data);
    });
  }, [clientId, templateFormId, templateService]);

  const getSystemPicklistEntries = useCallback(async () => {
    const res = await PickListService.getPickList(sourceType);
    setIsDynamic(!res.data.isSystem);
    setPicklistId(res.data.id);
    setPicklistTitle(LanguageUtils.getTranslation('name', res.data.translations));
    setPicklistOptions(
      res.data.items.map((item) => {
        return {
          id: item.id,
          text: LanguageUtils.getTranslation('name', item.translations),
          value: LanguageUtils.getTranslation('name', item.translations),
        };
      }),
    );
    setPicklistLoading(false);
  }, [sourceType]);

  const getClientFormsOptions = useCallback(
    async (assetTemplateId: string) => {
      if (!assetTemplateId || !client) {
        return Promise.resolve();
      }
      const formResult = await templateService.getFormTemplate(assetTemplateId);
      setIsDynamic(true);
      setIsAssetTemplate(true);
      setPicklistId(assetTemplateId);
      setPicklistTitle(FormUtils.getFormTitle({ form: formResult.data }));
      const formListResult = await ClientFormService.getForms(client.id, { templateIds: [assetTemplateId], isArchived: false });
      setPicklistOptions(
        formListResult.data
          .filter((x) => x.id !== clientFormId)
          .map((x_1) => ({
            id: x_1.id,
            text: `${x_1.subtitle || FormUtils.getFormTitle({ subtitle: x_1.subtitle, form: x_1 })}${FormUtils.formHostName(
              x_1,
              client?.id || '',
              ' ({client})',
            )}`,
            value: x_1.templateId,
          })),
      );
      setPicklistLoading(false);
    },
    [client, clientFormId, templateService],
  );

  const fetchPicklistData = useCallback(
    async (forceReload = false) => {
      if (!sourceType) {
        setSetupError(true);
        return;
      }

      if (sourceType === 'input') {
        return;
      }

      // Defer loading picklists untill we open the modal
      if (!forceReload && (picklistOptions.length > 0 || !picklistOpen)) {
        return;
      }

      setPicklistLoading(true);
      const internalSourceType = sourceType.startsWith(AssetTemplateSourceKey) ? AssetTemplateSourceKey : sourceType;
      switch (internalSourceType) {
        case CustomSourceKeyAssociciatedClients:
          await getAffiliatedEntries();
          break;
        case AssetTemplateSourceKey:
          await getClientFormsOptions(sourceType.replace(AssetTemplateSourceKey, ''));
          break;
        default:
          await getSystemPicklistEntries();
      }
    },
    [getAffiliatedEntries, getClientFormsOptions, getSystemPicklistEntries, picklistOpen, picklistOptions.length, sourceType],
  );

  useEffect(() => {
    fetchPicklistData();
  }, [fetchPicklistData]);

  const picklistAddCustom = (translations: Translations): Promise<string> => {
    if (isAssetTemplate) {
      return ClientFormService.allocateToClient({
        clientId: client?.id || '',
        subtitle: LanguageUtils.getTranslation('name', translations) ?? '',
        templateFormId: picklistId,
      }).then(async (res) => {
        await fetchPicklistData(true);
        return res.data.id;
      });
    } else {
      return PickListService.addCustomItem(client?.id || '', picklistId, translations).then(async (res) => {
        await fetchPicklistData(true);
        return res.data.id;
      });
    }
  };

  const onPicklistAdd = (selectedItems: Option<string, string>[]): void => {
    if (!selectedItems.length) {
      return;
    }

    instantiateNewForm(selectedItems.map((item) => item.text));
  };

  const onCustomAdd = (): void => {
    instantiateNewForm([customInput]);
    setCustomInput('');
  };

  const instantiateNewForm = (subtitles: string[]) => {
    if (!template) {
      toasts.addToast({
        title: t('child-form-list.error-creating'),
        description: t('child-form-list.error-creating-missing-template'),
        type: ToastType.ERROR,
      });
      return;
    }

    createFormBulk(
      subtitles.map((subtitle) => ({
        templateFormId: template.id,
        // TEMP: we're ignoring versions for now
        // templateFormVersion: templateFormVersion,
        clientId: clientId,
        subtitle: subtitle,
        clientModuleId: template.type === FormType.Asset ? undefined : containingModuleId,
        clientModuleSectionId: template.type === FormType.Asset ? undefined : containingModuleSectionId,
        parentId: clientFormId,
        parentSectionId: currentSection.id,
        parentActionId: currentAction.id,
      })),
    )
      .then((resp) => resp && onAddedResponse(resp))
      .catch((err) => {
        toasts.addToast({ title: t('child-form-list.error-creating'), description: err?.data?.meta?.message, type: ToastType.ERROR });
      });
  };

  const onAddedResponse = (formResponses: (ClientForm | null)[]): void => {
    const newForms = [
      ...forms,
      ...formResponses
        .filter((res) => res !== null)
        .map((res) => {
          return { id: res?.id || '' };
        }),
    ];

    onAnswer(newForms)
      .then(() => {
        setForms(newForms);
      })
      .catch((err) => {
        toasts.addToast({ title: t('child-form-list.error-creating'), description: err?.data?.meta?.message, type: ToastType.ERROR });
        onAnswer(forms);
        ClientFormService.deleteFormBulk(formResponses.filter(Boolean).map((x) => x.id));
      });
  };

  const deleteSubform = (form: ClientForm) => {
    ClientFormService.deleteForm(form.id).then(() => {
      const newForms = forms.filter((x) => x.id !== form.id);
      setForms(newForms);
      onAnswer(newForms.length ? newForms : null);
    });
  };

  return (
    <div data-cy="child-form-action">
      <ActionTitleDescription required={required} {...data} />
      {forms.map((subForm) => (
        <CollapsedForm
          formType={CollapsedFormType.Subform}
          formId={subForm.id}
          key={subForm.id}
          onValidate={onValid}
          onDelete={deleteSubform}
          showInline={(!isDisplayedInline && renderType === ChildFormRenderType.Inline) || type === FormRendererType.Public}
          canEdit={!readOnly}
          data-cy={`child-form-${subForm.id}`}
        />
      ))}

      {!!sourceType && sourceType !== 'input' && (
        <>
          <Button type={ButtonType.PRIMARY} onClick={() => setPicklistOpen(true)} disabled={readOnly} size={ButtonSize.S} data-cy="add-child-form">
            + {t('child-form-list.buttons.add')}
          </Button>
          <PickListModal
            setupError={setupError}
            title={picklistTitle}
            subTitle={question}
            items={picklistOptions}
            loading={picklistLoading}
            open={picklistOpen}
            onClose={() => setPicklistOpen(false)}
            onComplete={onPicklistAdd}
            single={false}
            addCustomValue={picklistAddCustom}
            showAddNew={isDynamic}
          />
        </>
      )}
      {sourceType === 'input' && (
        <CompoundInput
          value={customInput}
          disabled={readOnly}
          buttonDisabled={!customInput || readOnly}
          onClick={onCustomAdd}
          onChange={(event) => setCustomInput(event.target.value)}
          dataCy="child-form-input"
        />
      )}
    </div>
  );
};

export default ChildFormListAction;
